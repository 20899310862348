<template>
  <div class="leave-form">
    <!-- 请假详细原因 -->
    <el-form :model="leaveForm" label-width="120px">
      <el-form-item label="请假详细原因">
        <el-input v-model="leaveForm.reason" placeholder="请输入请假原因"></el-input>
      </el-form-item>

      <el-form-item label="请假类别">
        <el-select v-model="leaveForm.excuseClass" placeholder="请选择请假类别">
          <el-option label="事假" value="事假"></el-option>
          <el-option label="病假" value="病假"></el-option>
          <el-option label="实习" value="实习"></el-option>
          <el-option label="假期离呼" value="假期离呼"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
      </el-form-item>
      <!-- 目的地 -->
      <el-form-item label="目的地省">
        <el-input v-model="leaveForm.destination" placeholder="请输入目的地省"></el-input>
      </el-form-item>
      <el-form-item label="目的地市">
        <el-input v-model="leaveForm.destinationCity" placeholder="请输入目的地市"></el-input>
      </el-form-item>

      <!-- 详细地址 -->
      <el-form-item label="详细地址">
        <el-input v-model="leaveForm.address" placeholder="请输入详细地址"></el-input>
      </el-form-item>

      <!-- 出行方式 -->
      <el-form-item label="出行方式">
        <el-input v-model="leaveForm.travelMode" placeholder="请输入出行方式"></el-input>
      </el-form-item>

      <!-- 请假开始时间 -->
      <el-form-item label="请假开始时间">
        <el-date-picker
            v-model="leaveForm.startDate"
            type="datetime"
            placeholder="选择开始时间">
        </el-date-picker>
      </el-form-item>

      <!-- 请假结束时间 -->
      <el-form-item label="请假结束时间">
        <el-date-picker
            v-model="leaveForm.endDate"
            type="datetime"
            placeholder="选择结束时间">
        </el-date-picker>
      </el-form-item>

      <!-- 提交按钮 -->
      <el-form-item>
        <el-button type="primary" @click="submitForm">页面展示</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="copyLink">复制链接</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible" title="复制内容" width="350px">
      <div>
        <el-input
            v-model="copyText"
            type="textarea"
            class="full-width-tall-input"
        ></el-input>
      </div>
    </el-dialog>
  </div>
  <!-- 弹出框 -->

</template>

<script>

function generateUUID() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}

export default {
  data() {
    return {
      info: {},
      dialogVisible: false,
      copyText: "这是需要复制的内容", // 你想要复制的文本内容
      leaveForm: {
        reason: '有事别管',        // 请假详细原因
        excuseClass: '',  // 请假类别
        destination: '内蒙古自治区',   // 目的地省
        destinationCity: '呼和浩特市',  // 目的地市
        address: '',       // 详细地址
        travelMode: '公交',    // 出行方式
        startDate: '',     // 请假开始时间
        endDate: ''        // 请假结束时间
      },
      infoForm: {}
    };
  },
  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    submitForm() {
      console.log('提交的请假信息：', this.leaveForm);
      let info = JSON.parse(sessionStorage.getItem("userInfo"));
      this.$axios
          .post(this.$httpUrl + "/info/submit", info)
          .then((res) => res.data)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success('请假申请提交成功！扣去一次生成机会');

              // 使用 query 参数传递数据
              this.$router.push({
                path: '/last',
                query: {leaveForm: JSON.stringify(this.leaveForm)}
              });
            } else {
              alert("生成失败,原因:" + res.data);
              return false;
            }
          }).catch(e => {
        alert("生成失败,原因:" + e);
        return false;
      });

    },
    copyLink() {
      this.info = JSON.parse(sessionStorage.getItem("userInfo"));
      const formData = {
        ...this.info,
        ...this.leaveForm
      };
      this.$axios
          .post(this.$httpUrl + "/info/saveinfo", formData)
          .then((res) => res.data)
          .then((res) => {
            if (res.code == 200) {
              //跳转到主页t
              document.execCommand("copy");
              const uuid = generateUUID();
              const uuid2 = generateUUID();
              const uuid3 = generateUUID();
              alert("获得成功,扣去一次生成次数");
              this.copyText = "http://" + window.location.hostname + "/x_desktop/workmobilewithactionhtml?workid=" + res.data +
                  "&workCompletedId=" + uuid + "&docTitle=%E8%AF%E6%%B71%87%E6%9D%A1&" +
                  "appId=process.Work" + uuid2 + "&redirectlink=%2Fx_desktop%2Fportalmobile.html%3Fid%3D" + uuid3;
              this.openDialog();
            } else {
              alert("获取失败,原因:" + res.data);
            }
          }).catch(e => {
        alert("获取失败,原因:" + e);
        return false;
      });
    },

  }
};

</script>

<style scoped>
.leave-form {
  width: 600px;
  margin: 50px auto;
}

.el-form-item {
  margin-bottom: 20px;
}

.full-width-tall-input {
  width: 100%;
  height: 100px;
  resize: none; /* 禁止用户调整大小 */
  padding: 10px; /* 添加一些内边距 */
  box-sizing: border-box; /* 确保padding不会超出容器 */
}
</style>
